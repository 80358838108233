import React from 'react';
import { useState, useEffect } from 'react';
import { post, get } from '../../util/request';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { userInfo } from '../../redux/slice';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Qs from 'qs';
import { useTranslation } from 'react-i18next';
import './index.scss';
import axios from 'axios';
import Loading from '../../components/common/loading';

const Investments = () => {
  const dispatch = useDispatch();

  const user = useSelector(userInfo);
  const { id, name } = useParams();
  const history = useHistory();

  //获取azure返回的token和objectId
  // const locationState = history.location.search;
  // var obj = Qs.parse(locationState, { ignoreQueryPrefix: true });
  // const name = obj.name;



  const [isButton, setButton] = useState(true);

  const [pageNum, setPageNum] = useState(0);
  const [pageSize, setPageSize] = useState(50);

  const [listData, setListData] = useState({
    activeProjects: [],
    exitedProjects: [],
    exitingProjects: [],
    booklet: '',
    statement: ''
  });

  const [totalActiveHolding, setTotalActiveHolding] = useState({});
  const [totalActiveLoanHolding, setTotalActiveLoanHolding] = useState({});
  const [totalGrossHolding, setTotalGrossHolding] = useState({});
  const [totalPaymentAmount, setTotalPaymentAmount] = useState({});
  const [pending, setpending] = useState(true);
  const [representative, setRepresentative] = useState();

  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(false)
  const LAND = () => {
    setButton(true);
    init('PDLI');
  };
  const LOAN = () => {
    setButton(false);
    init('LOAN');
  };
  const formatCurrency = (value) => {
    if (value) {
      return Number(value).toLocaleString()
    } else {
      return 0
    }

  }
  const formatAmountWithC = (value, currency, translateCurency = false, space = true) => {
    if (typeof value !== 'number') return value;
    const mCurency = translateCurency ? currency : currency;
    const formatedValue = value ? Number(value).toLocaleString() : 0;
    const arr = ['$ ', formatedValue, space ? ' ' : '', mCurency];
    return typeof mCurency === 'string' ? arr.join('') : arr;
  };

  const block = () => {
    if (pending === true) {
      setpending(!pending);
    }
  };

  const strip = () => {
    if (pending === false) {
      setpending(!pending);
    }
  };
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const landList = (list, activeTab) => {
    let currutList = [];

    if (activeTab == 0) {
      currutList = list.activeProjects;
    } else if (activeTab == 1) {
      currutList = list.exitingProjects;
    } else {
      currutList = list.exitedProjects;
    }
    return (
      <>
        <div className="mobile-box">
          {
            currutList?.map((item) => (
              <>
                {
                  isButton ? (
                    <div className="moblie-list">
                      <div className="mobile-item">
                        <div className="moblie-list-title">{t('Project Name')}</div>
                        <div className="moblie-list-name">{item.projectName}</div>
                      </div>
                      <div className="mobile-item">
                        <div className="moblie-list-title">{t('State / Province')}</div>
                        <div className="moblie-list-name">{item.location}</div>
                      </div>
                      <div className="mobile-item">
                        <div className="moblie-list-title">{t('PurchaseAmount')}</div>
                        <div className="moblie-list-name"> {item.purchaseCurrency === 'CAD' ? t('CAD') : t('USD')}
                          <span className="f-s-20">&nbsp;{formatCurrency(item.purchaseAmount)}&nbsp;</span>
                          {item.purchaseCurrency === 'CAD' ? t('CAD1') : t('USD1')}</div>
                      </div>
                      <div className="mobile-item">
                        <div className="moblie-list-title">{t('LastPurchaseDate')}</div>
                        <div className="moblie-list-name">{item.purchaseDate}</div>
                      </div>
                      <div className="mobile-item">
                        <div className="moblie-list-title">{t('Disposition Status')}</div>
                        <div className="moblie-list-name">{item.dispositionStatus}</div>
                      </div>
                    </div>
                  ) : (
                    <div className="moblie-list">
                      <div className="mobile-item">
                        <div className="moblie-list-title">{t('Project Name')}</div>
                        <div className="moblie-list-name">{item.projectName}</div>
                      </div>
                      <div className="mobile-item">
                        <div className="moblie-list-title">{t('State / Province')}</div>
                        <div className="moblie-list-name">{item.location}</div>
                      </div>
                      <div className="mobile-item">
                        <div className="moblie-list-title">{t('OriginalPrincipal')}</div>
                        <div className="moblie-list-name"> {item.purchaseCurrency === 'CAD' ? t('CAD') : t('USD')}
                          <span className="f-s-20">&nbsp;{formatCurrency(item.initialInvestment)}&nbsp;</span>
                          {item.purchaseCurrency === 'CAD' ? t('CAD1') : t('USD1')}</div>
                      </div>
                      <div className="mobile-item">
                        <div className="moblie-list-title">{t('LastPurchaseDate')}</div>
                        <div className="moblie-list-name">{item.purchaseDate}</div>
                      </div>
                      <div className="mobile-item">
                        <div className="moblie-list-title">{t('Outstanding Balance')}</div>
                        <div className="moblie-list-name"> {item.purchaseCurrency === 'CAD' ? t('CAD') : t('USD')}
                          <span className="f-s-20">&nbsp;{formatCurrency(item.purchaseAmount)}&nbsp;</span>
                          {item.purchaseCurrency === 'CAD' ? t('CAD1') : t('USD1')}</div>
                      </div>
                      <div className="mobile-item">
                        <div className="moblie-list-title">{t('Latest Interest Cutoff Date')}</div>
                        <div className="moblie-list-name">{item.paymentDate}</div>
                      </div>
                      <div className="mobile-item">
                        <div className="moblie-list-title">{t('TotalDistribution')}</div>
                        <div className="moblie-list-name"> {formatAmountWithC(item.distributionAmount, item.distributionCurrency, true)}</div>
                      </div>
                    </div>
                  )
                }
              </>
            ))
          }
        </div>
        <Table className="table-box  align-middle">
          <thead>
            <tr>
              {isButton ? (
                <>
                  <th>{t('Project Name')}</th>
                  <th>{t('State / Province')}</th>
                  <th>{t('PurchaseAmount')}</th>
                  <th>{t('LastPurchaseDate')}</th>
                  <th>{t('Disposition Status')}</th>
                </>
              ) : (
                <>
                  <th>{t('Project Name')}</th>
                  <th>{t('State / Province')}</th>
                  <th>{t('OriginalPrincipal')}</th>
                  <th>{t('LastPurchaseDate')}</th>
                  <th>{t('Outstanding Balance')}</th>
                  <th>{t('Latest Interest Cutoff Date')}</th>
                  <th>{t('TotalDistribution')}</th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {currutList?.map((item) => (
              <tr>
                {
                  isButton ? (
                    <>

                      <td className="name">{item.projectName}</td>
                      <td className="place">{item.location}</td>

                      <td className="amount">
                        {/* {item.purchaseCurrency}&nbsp;&nbsp; */}
                        {item.purchaseCurrency === 'CAD' ? t('CAD') : t('USD')}
                        <span className="f-s-20">&nbsp;{formatCurrency(item.purchaseAmount)}&nbsp;</span>
                        {item.purchaseCurrency === 'CAD' ? t('CAD1') : t('USD1')}
                      </td>
                      <td className="date">{item.purchaseDate}</td>
                      <td className="status">{item.dispositionStatus}</td>
                    </>
                  ) : (
                    <>
                      <td className="name">{item.projectName}</td>
                      <td className="place">{item.location}</td>
                      <td className="amount">
                        {/* {item.purchaseCurrency + ' '} */}
                        {item.purchaseCurrency === 'CAD' ? t('CAD') : t('USD')}
                        <span className="f-s-20">&nbsp;{formatCurrency(item.initialInvestment)}&nbsp;</span>
                        {item.purchaseCurrency === 'CAD' ? t('CAD1') : t('USD1')}
                      </td>
                      <td className="date">{item.purchaseDate}</td>
                      <td className="status">
                        {/* {(formatAmountWithC(item.purchaseAmount, item.purchaseCurrency, true))} */}
                        {item.purchaseCurrency === 'CAD' ? t('CAD') : t('USD')}
                        <span className="f-s-20">&nbsp;{formatCurrency(item.purchaseAmount)}&nbsp;</span>
                        {item.purchaseCurrency === 'CAD' ? t('CAD1') : t('USD1')}
                        {/* formatCurrency */}
                      </td>
                      <td className="status">{item.paymentDate}</td>
                      <td className="status">
                        {formatAmountWithC(item.distributionAmount, item.distributionCurrency, true)}
                      </td>
                    </>
                  )}
              </tr>
            ))}
          </tbody>
        </Table>
      </>
    );
  };

  const landCard = (list, activeTab) => {
    let currutList = [];

    if (activeTab == 0) {
      currutList = list.activeProjects;
    } else if (activeTab == 1) {
      currutList = list.exitingProjects;
    } else {
      currutList = list.exitedProjects;
    }
    return (
      <>
        <div className="mobile-box">
          {
            currutList?.map((item) => (
              <>
                {
                  isButton ? (
                    <div className="moblie-list">
                      <div className="mobile-item">
                        <div className="moblie-list-title">{t('Project Name')}</div>
                        <div className="moblie-list-name">{item.projectName}</div>
                      </div>
                      <div className="mobile-item">
                        <div className="moblie-list-title">{t('State / Province')}</div>
                        <div className="moblie-list-name">{item.location}</div>
                      </div>
                      <div className="mobile-item">
                        <div className="moblie-list-title">{t('PurchaseAmount')}</div>
                        <div className="moblie-list-name">
                          {item.purchaseCurrency === 'CAD' ? t('CAD') : t('USD')}
                          <span className="f-s-20">&nbsp;{formatCurrency(item.purchaseAmount)}&nbsp;</span>
                          {item.purchaseCurrency === 'CAD' ? t('CAD1') : t('USD1')}</div>
                      </div>
                      <div className="mobile-item">
                        <div className="moblie-list-title">{t('LastPurchaseDate')}</div>
                        <div className="moblie-list-name">{item.purchaseDate}</div>
                      </div>
                      <div className="mobile-item">
                        <div className="moblie-list-title">{t('Disposition Status')}</div>
                        <div className="moblie-list-name">{item.dispositionStatus}</div>
                      </div>
                    </div>
                  ) : (
                    <div className="moblie-list">
                      <div className="mobile-item">
                        <div className="moblie-list-title">{t('Project Name')}</div>
                        <div className="moblie-list-name">{item.projectName}</div>
                      </div>
                      <div className="mobile-item">
                        <div className="moblie-list-title">{t('State / Province')}</div>
                        <div className="moblie-list-name">{item.location}</div>
                      </div>
                      <div className="mobile-item">
                        <div className="moblie-list-title">{t('OriginalPrincipal')}</div>
                        <div className="moblie-list-name">
                          {item.purchaseCurrency === 'CAD' ? t('CAD') : t('USD')}
                          <span className="f-s-20">&nbsp;{formatCurrency(item.initialInvestment)}&nbsp;</span>
                          {item.purchaseCurrency === 'CAD' ? t('CAD1') : t('USD1')}</div>
                      </div>
                      <div className="mobile-item">
                        <div className="moblie-list-title">{t('LastPurchaseDate')}</div>
                        <div className="moblie-list-name">{item.purchaseDate}</div>
                      </div>
                      <div className="mobile-item">
                        <div className="moblie-list-title">{t('Outstanding Balance')}</div>
                        <div className="moblie-list-name"> {item.purchaseCurrency === 'CAD' ? t('CAD') : t('USD')}
                          <span className="f-s-20">&nbsp;{formatCurrency(item.purchaseAmount)}&nbsp;</span>
                          {item.purchaseCurrency === 'CAD' ? t('CAD1') : t('USD1')}</div>
                      </div>
                      <div className="mobile-item">
                        <div className="moblie-list-title">{t('Latest Interest Cutoff Date')}</div>
                        <div className="moblie-list-name">{item.paymentDate}</div>
                      </div>
                      <div className="mobile-item">
                        <div className="moblie-list-title">{t('TotalDistribution')}</div>
                        <div className="moblie-list-name"> {formatAmountWithC(item.distributionAmount, item.distributionCurrency, true)}</div>
                      </div>
                    </div>
                  )
                }
              </>
            ))
          }
        </div>
        <div className="list-box">
          {currutList?.map((item) => (
            <>
              {isButton ? (
                <div className="list">
                  <div className="list-label d-flex justify-content-between">
                    <div className="name-box">
                      <span className="name">{item.projectName}</span>
                    </div>
                    <div className="list-content m-r-32 flex-1">
                      <span>{t('Total Active Loan Amount')}</span>
                      <p className="amount">
                        {item.purchaseCurrency === 'CAD' ? t('CAD') : t('USD')}
                        {/* {item.purchaseCurrency}&nbsp;&nbsp; */}
                        <span className="f-s-20">&nbsp;{formatCurrency(item.purchaseAmount)}&nbsp;</span>
                        {item.purchaseCurrency === 'CAD' ? t('CAD1') : t('USD1')}
                      </p>
                    </div>

                  </div>
                  <div className="list-padding">
                    <div className="place m-l-32">{item.location}</div>
                    <div className="divider"></div>
                    <div className="d-flex justify-content-between">

                      <div className="list-content flex-1">
                        <span>{t('Purchase Date')}</span>
                        <p className="date">{item.purchaseDate}</p>
                      </div>
                      <div className="list-content flex-1">
                        <span>{t('Disposition Status')}</span>
                        <p className="status">{item.dispositionStatus}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="list-loan">
                  <div className="list-label d-flex justify-content-between">
                    <div className="name-box">
                      <span className="name">{item.projectName}</span>
                    </div>
                    <div className="list-top m-r-32 flex-1">
                      <span>{t('OriginalPrincipal')}</span>
                      <p className="amount">
                        {/* {item.purchaseCurrency + ' ' + formatCurrency(item.initialInvestment)} */}
                        {item.purchaseCurrency === 'CAD' ? t('CAD') : t('USD')}
                        <span className="f-s-20">&nbsp;{formatCurrency(item.initialInvestment)}&nbsp;</span>
                        {item.purchaseCurrency === 'CAD' ? t('CAD1') : t('USD1')}
                      </p>
                    </div>
                  </div>

                  <div className="list-padding">
                    <div className="place">{item.location}</div>
                    <div className="divider"></div>
                    <div className="d-flex justify-content-between">
                      <div className="list-top flex-1">
                        <span>{t('LastPurchaseDate')}</span>
                        <p className="date">{item.purchaseDate}</p>
                      </div>
                      <div className="list-top flex-1">
                        <span>{t('Outstanding Balance')}</span>
                        <p className="status">
                          {item.purchaseCurrency === 'CAD' ? t('CAD') : t('USD')}
                          <span className="f-s-20">&nbsp;{formatCurrency(item.purchaseAmount)}&nbsp;</span>
                          {item.purchaseCurrency === 'CAD' ? t('CAD1') : t('USD1')}
                          {/* {formatAmountWithC(item.purchaseAmount, item.purchaseCurrency, true)} */}
                        </p>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="list-content flex-1">
                        <span>{t('Latest Interest Cutoff Date')}</span>
                        <p className="amount">{item.paymentDate}</p>
                      </div>
                      <div className="list-content flex-1">
                        <span>{t('TotalDistribution')}</span>
                        <p className="date">{formatAmountWithC(item.distributionAmount, item.distributionCurrency, true)}</p>
                      </div>
                    </div>

                  </div>
                </div>
              )}
            </>
          ))}
        </div>
      </>
    );
  };

  const tabLANDObj = [
    { name: t('Pending Offer'), key: 0 },
    { name: t('Offer Received'), key: 1 },
    { name: t('Exits & Closings'), key: 2 },
  ];

  const tabLAODObj = [
    { name: t('Active'), key: 0 },
    { name: t('Offer Received'), key: 1 },
    { name: t('Exits & Closings'), key: 2 },
  ];

  const getTotal = () => {
    let currutList = [
      listData.activeProjects?.length,
      listData.exitingProjects?.length,
      listData.exitedProjects?.length,
    ];

    return currutList;
  };

  const landpage = (tabObj) => {
    let total = getTotal();
    return (
      <div>
        {tabObj.map((item, index) => {
          return (
            <div className="pending-box">
              <div className="Pending d-flex justify-content-between">
                <div
                  onClick={() => {
                    setActiveTab(item.key);
                  }}
                >
                  <p className='left-border'>
                    {item.name} ({total[index]})
                  </p>
                </div>
                {index == 0 ? (
                  <div className="d-flex togge-table">
                    <div style={{ cursor: "pointer" }} onClick={() => block()}>
                      {pending ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M5 4C4.44772 4 4 4.44772 4 5V10C4 10.5523 4.44772 11 5 11H10C10.5523 11 11 10.5523 11 10V5C11 4.44772 10.5523 4 10 4H5ZM14 4C13.4477 4 13 4.44772 13 5V10C13 10.5523 13.4477 11 14 11H19C19.5523 11 20 10.5523 20 10V5C20 4.44772 19.5523 4 19 4H14ZM4 14C4 13.4477 4.44772 13 5 13H10C10.5523 13 11 13.4477 11 14V19C11 19.5523 10.5523 20 10 20H5C4.44772 20 4 19.5523 4 19V14ZM14 13C13.4477 13 13 13.4477 13 14V19C13 19.5523 13.4477 20 14 20H19C19.5523 20 20 19.5523 20 19V14C20 13.4477 19.5523 13 19 13H14Z"
                            fill="#9B9B9B"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M5 4C4.44772 4 4 4.44772 4 5V10C4 10.5523 4.44772 11 5 11H10C10.5523 11 11 10.5523 11 10V5C11 4.44772 10.5523 4 10 4H5ZM14 4C13.4477 4 13 4.44772 13 5V10C13 10.5523 13.4477 11 14 11H19C19.5523 11 20 10.5523 20 10V5C20 4.44772 19.5523 4 19 4H14ZM4 14C4 13.4477 4.44772 13 5 13H10C10.5523 13 11 13.4477 11 14V19C11 19.5523 10.5523 20 10 20H5C4.44772 20 4 19.5523 4 19V14ZM14 13C13.4477 13 13 13.4477 13 14V19C13 19.5523 13.4477 20 14 20H19C19.5523 20 20 19.5523 20 19V14C20 13.4477 19.5523 13 19 13H14Z"
                            fill="#003448"
                          />
                        </svg>
                      )}
                    </div>
                    <div style={{ cursor: "pointer" }} onClick={() => strip()}>
                      {pending ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M4 5.5C4 4.67157 4.67157 4 5.5 4H18.5C19.3284 4 20 4.67157 20 5.5C20 6.32843 19.3284 7 18.5 7H5.5C4.67157 7 4 6.32843 4 5.5ZM4 12C4 11.1716 4.67157 10.5 5.5 10.5H18.5C19.3284 10.5 20 11.1716 20 12C20 12.8284 19.3284 13.5 18.5 13.5H5.5C4.67157 13.5 4 12.8284 4 12ZM5.5 17C4.67157 17 4 17.6716 4 18.5C4 19.3284 4.67157 20 5.5 20H18.5C19.3284 20 20 19.3284 20 18.5C20 17.6716 19.3284 17 18.5 17H5.5Z"
                            fill="#003448"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M4 5.5C4 4.67157 4.67157 4 5.5 4H18.5C19.3284 4 20 4.67157 20 5.5C20 6.32843 19.3284 7 18.5 7H5.5C4.67157 7 4 6.32843 4 5.5ZM4 12C4 11.1716 4.67157 10.5 5.5 10.5H18.5C19.3284 10.5 20 11.1716 20 12C20 12.8284 19.3284 13.5 18.5 13.5H5.5C4.67157 13.5 4 12.8284 4 12ZM5.5 17C4.67157 17 4 17.6716 4 18.5C4 19.3284 4.67157 20 5.5 20H18.5C19.3284 20 20 19.3284 20 18.5C20 17.6716 19.3284 17 18.5 17H5.5Z"
                            fill="#9B9B9B"
                          />
                        </svg>
                      )}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              {activeTab == item.key ? (
                <div>{pending ? landList(listData, activeTab) : landCard(listData, activeTab)}</div>
              ) : (
                <></>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  const init = async (tab) => {
    if (id) {

      let params = {
        accessToken: user.accessToken,
        userId: user.userId,
      };
      let url = '/businessPartner/client/' + id + '/investments/' + tab;
      const data = await get(url, params);
      if (data) {

        if (data.activeProjects.length > 0) {
          for (let i = 0; i < data.activeProjects.length; i++) {
            var day = data.activeProjects[i].purchaseDate.slice(8, 10)
            var year = data.activeProjects[i].purchaseDate.slice(0, 4)
            var month = data.activeProjects[i].purchaseDate.slice(5, 7)
            data.activeProjects[i].purchaseDate = year + ' / ' + month + ' / ' + day

            var day = data.activeProjects[i].paymentDate.slice(8, 10)
            var year = data.activeProjects[i].paymentDate.slice(0, 4)
            var month = data.activeProjects[i].paymentDate.slice(5, 7)
            if (data.activeProjects[i].paymentDate) {
              data.activeProjects[i].paymentDate = year + ' / ' + month + ' / ' + day
            }
          }
        }

        if (data.exitedProjects.length > 0) {
          for (let i = 0; i < data.exitedProjects.length; i++) {
            var day = data.exitedProjects[i].purchaseDate.slice(8, 10)
            var year = data.exitedProjects[i].purchaseDate.slice(0, 4)
            var month = data.exitedProjects[i].purchaseDate.slice(5, 7)
            data.exitedProjects[i].purchaseDate = year + ' / ' + month + ' / ' + day

            var day = data.exitedProjects[i].paymentDate.slice(8, 10)
            var year = data.exitedProjects[i].paymentDate.slice(0, 4)
            var month = data.exitedProjects[i].paymentDate.slice(5, 7)
            if (data.exitedProjects[i].paymentDate) {
              data.exitedProjects[i].paymentDate = year + ' / ' + month + ' / ' + day
            }
          }
        }
        if (data.exitingProjects.length > 0) {
          for (let i = 0; i < data.exitingProjects.length; i++) {
            var day = data.exitingProjects[i].purchaseDate.slice(8, 10)
            var year = data.exitingProjects[i].purchaseDate.slice(0, 4)
            var month = data.exitingProjects[i].purchaseDate.slice(5, 7)
            data.exitingProjects[i].purchaseDate = year + ' / ' + month + ' / ' + day

            var day = data.exitingProjects[i].paymentDate.slice(8, 10)
            var year = data.exitingProjects[i].paymentDate.slice(0, 4)
            var month = data.exitingProjects[i].paymentDate.slice(5, 7)
            if (data.exitingProjects[i].paymentDate) {
              data.exitingProjects[i].paymentDate = year + ' / ' + month + ' / ' + day
            }
          }
        }
        setListData({
          activeProjects: data.activeProjects != '' ? data.activeProjects : [],
          exitedProjects: data.exitedProjects != '' ? data.exitedProjects : [],
          exitingProjects: data.exitingProjects != '' ? data.exitingProjects : [],
          booklet: data?.booklet,
          statement: data?.statement
        });
        setTotalActiveHolding(data.totalInvestmentAmount);
        setTotalActiveLoanHolding(data.totalLoanAmount);
        setTotalPaymentAmount(data.totalPaymentAmount);
        setRepresentative(data.representative)

      }
    } else {
      let params = {
        accessToken: user.accessToken,
        userId: user.userId,
      };
      let url = '/investments/' + tab;
      const data = await get(url, params);
      if (data) {

        if (data.activeProjects.length > 0) {
          for (let i = 0; i < data.activeProjects.length; i++) {
            var day = data.activeProjects[i].purchaseDate.slice(8, 10)
            var year = data.activeProjects[i].purchaseDate.slice(0, 4)
            var month = data.activeProjects[i].purchaseDate.slice(5, 7)
            data.activeProjects[i].purchaseDate = year + ' / ' + month + ' / ' + day

            var day = data.activeProjects[i].paymentDate.slice(8, 10)
            var year = data.activeProjects[i].paymentDate.slice(0, 4)
            var month = data.activeProjects[i].paymentDate.slice(5, 7)
            if (data.activeProjects[i].paymentDate) {
              data.activeProjects[i].paymentDate = year + ' / ' + month + ' / ' + day
            }
          }
        }

        if (data.exitedProjects.length > 0) {
          for (let i = 0; i < data.exitedProjects.length; i++) {
            var day = data.exitedProjects[i].purchaseDate.slice(8, 10)
            var year = data.exitedProjects[i].purchaseDate.slice(0, 4)
            var month = data.exitedProjects[i].purchaseDate.slice(5, 7)
            data.exitedProjects[i].purchaseDate = year + ' / ' + month + ' / ' + day

            var day = data.exitedProjects[i].paymentDate.slice(8, 10)
            var year = data.exitedProjects[i].paymentDate.slice(0, 4)
            var month = data.exitedProjects[i].paymentDate.slice(5, 7)
            if (data.exitedProjects[i].paymentDate) {
              data.exitedProjects[i].paymentDate = year + ' / ' + month + ' / ' + day
            }
          }
        }

        if (data.exitingProjects.length > 0) {
          for (let i = 0; i < data.exitingProjects.length; i++) {
            var day = data.exitingProjects[i].purchaseDate.slice(8, 10)
            var year = data.exitingProjects[i].purchaseDate.slice(0, 4)
            var month = data.exitingProjects[i].purchaseDate.slice(5, 7)
            data.exitingProjects[i].purchaseDate = year + ' / ' + month + ' / ' + day
            var day = data.exitingProjects[i].paymentDate.slice(8, 10)
            var year = data.exitingProjects[i].paymentDate.slice(0, 4)
            var month = data.exitingProjects[i].paymentDate.slice(5, 7)
            if (data.exitingProjects[i].paymentDate) {
              data.exitingProjects[i].paymentDate = year + ' / ' + month + ' / ' + day
            }
          }
        }

        setListData({
          activeProjects: data.activeProjects != '' ? data.activeProjects : [],
          exitedProjects: data.exitedProjects != '' ? data.exitedProjects : [],
          exitingProjects: data.exitingProjects != '' ? data.exitingProjects : [],
          booklet: data?.booklet,
          statement: data?.statement
        });
        setTotalActiveHolding(data.totalInvestmentAmount);
        setTotalActiveLoanHolding(data.totalLoanAmount);
        setTotalPaymentAmount(data.totalPaymentAmount);
        setRepresentative(data.representative)
      }
    }
  };


  const [datetime, setDatetime] = useState('');
  const getdate = () => {
    var time = new Date();
    var year = time.getFullYear();
    // var month = time.getMonth(); // 月份从0开始，因此需要加1
    var month = time.getMonth() + 1 < 10 ? "0" + (time.getMonth() + 1) : time.getMonth() + 1;
    var day = time.getDate() < 10 ? "0" + time.getDate() : time.getDate();
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    console.log(months[month])
    // setDatetime(months[month] + ' ' + day + ', ' + year)
    setDatetime(year + '/' + month + '/' + day)
  }
  const downloadFile = async (apiUrl, fileName, clientID) => {
    setLoading(true)
    axios.get(process.env.REACT_APP_BASEPATH + apiUrl, {
      responseType: 'blob',
      headers: {
        accessToken: user.accessToken,
        userId: user.userId,
        clientID: clientID
      }
    }).then((res) => {
      if (res.data.size === 0) { return }
      setLoading(false)
      const blob = new Blob([res.data], {
        type: 'application/pdf'
      })
      const objectUrl = URL.createObjectURL(blob)
      const link = document.createElement('a'); // 创建a标签
      link.href = objectUrl;
      link.download = fileName;
      link.click(); // 下载文件
      URL.revokeObjectURL(objectUrl);

    }).catch((err) => {
      console.log('---err---', err)
    })
  }
  const goto = () => {

    history.push({
      pathname: '/viewClient',
    });
  }
  useEffect(() => {
    if (user.accessToken && user.userId) {
      init('PDLI');
      getdate();
    }
  }, []);
  return (
    <div className="investments">
      {loading ? <Loading /> : null}

      <div className="main-box">
        <div className="headline">
          {
            id || name ? (
              <>
                < div className='back-button pointer d-flex' onClick={goto}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M16.1939 18.3047C16.3142 18.425 16.377 18.5777 16.377 18.7488C16.377 18.9198 16.3142 19.0726 16.1939 19.1929C16.0739 19.3129 15.9225 19.376 15.7534 19.376C15.5844 19.376 15.433 19.3129 15.313 19.1929L8.56004 12.44C8.44005 12.32 8.37695 12.1685 8.37695 11.9995C8.37695 11.8305 8.44005 11.6791 8.56004 11.5591L15.3016 4.81828C15.363 4.74816 15.438 4.69582 15.5242 4.6641C15.5981 4.63684 15.675 4.62305 15.7534 4.62305C15.8318 4.62305 15.9087 4.63684 15.9827 4.6641C16.0688 4.69582 16.1439 4.74816 16.1939 4.80613C16.3139 4.92613 16.377 5.07755 16.377 5.24658C16.377 5.41561 16.3139 5.56704 16.194 5.68693L9.88861 11.9994L16.1939 18.3047Z" fill="#5582A0" />
                  </svg>
                  <p className="back-text">{t('View Clients')}</p>
                </div>
              </>
            ) : (<></>)
          }
          <p>{t('Investments')}</p>
        </div>
        <div className="statement-box">
          <div className="name">
            <p>{id ? name : user.username}</p>
          </div>
          <div className="id">
            <span>{t('Client ID')}: {id ? id : user.clientID}</span>
          </div>
          <div className="representative">
            {/* <span>{t('Walton Representative')}: {user.representative}</span> */}
            <span>{t('Walton Representative')}: {representative}</span>
          </div>
          <div className="updatabtn">
            <button
              type="button"
              class=" btn btn-outline-theme"
              disable
              onClick={() => {
                if (user.sfUserType == 'CL') {
                  downloadFile('/appUser/booklet', user.bookletFileName)
                } else {
                  downloadFile(`/businessPartner/client/${id}/booklet`, user.bookletFileName, id)
                }
                // window.open(listData.booklet);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12.4167 4C12.7964 4 13.1102 4.28215 13.1598 4.64823L13.1667 4.75V13.9385L16.553 10.553C16.8193 10.2867 17.2359 10.2625 17.5295 10.4804L17.6137 10.553C17.8799 10.8193 17.9041 11.2359 17.6863 11.5295L17.6137 11.6137L12.947 16.2803C12.6807 16.5466 12.2641 16.5708 11.9705 16.3529L11.8863 16.2803L7.21967 11.6137C6.92678 11.3208 6.92678 10.8459 7.21967 10.553C7.48594 10.2867 7.9026 10.2625 8.19621 10.4804L8.28033 10.553L11.6667 13.9396V4.75C11.6667 4.33579 12.0025 4 12.4167 4ZM19.8265 17.3149C19.7768 16.9488 19.463 16.6667 19.0833 16.6667L18.9816 16.6735C18.6155 16.7232 18.3333 17.037 18.3333 17.4167L18.333 18H6.5V17.4167L6.49315 17.3149C6.44349 16.9488 6.1297 16.6667 5.75 16.6667C5.33579 16.6667 5 17.0025 5 17.4167V18.75L5.00685 18.8518C5.05651 19.2178 5.3703 19.5 5.75 19.5H19.0833L19.1851 19.4932C19.5512 19.4435 19.8333 19.1297 19.8333 18.75V17.4167L19.8265 17.3149Z"
                  fill="#003448"
                />
              </svg>
              {t('Last Project Update')}
            </button>
          </div>
          <div className="statement">
            <button
              type="button"
              class=" btn btn-outline-theme"
              disable
              onClick={() => {
                if (user.sfUserType == 'CL') {
                  downloadFile('/appUser/statement', user.statementFileName)
                } else {
                  downloadFile(`/businessPartner/client/${id}/statement`, user.statementFileName, id)
                }
                // window.open(listData.statement);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12.4167 4C12.7964 4 13.1102 4.28215 13.1598 4.64823L13.1667 4.75V13.9385L16.553 10.553C16.8193 10.2867 17.2359 10.2625 17.5295 10.4804L17.6137 10.553C17.8799 10.8193 17.9041 11.2359 17.6863 11.5295L17.6137 11.6137L12.947 16.2803C12.6807 16.5466 12.2641 16.5708 11.9705 16.3529L11.8863 16.2803L7.21967 11.6137C6.92678 11.3208 6.92678 10.8459 7.21967 10.553C7.48594 10.2867 7.9026 10.2625 8.19621 10.4804L8.28033 10.553L11.6667 13.9396V4.75C11.6667 4.33579 12.0025 4 12.4167 4ZM19.8265 17.3149C19.7768 16.9488 19.463 16.6667 19.0833 16.6667L18.9816 16.6735C18.6155 16.7232 18.3333 17.037 18.3333 17.4167L18.333 18H6.5V17.4167L6.49315 17.3149C6.44349 16.9488 6.1297 16.6667 5.75 16.6667C5.33579 16.6667 5 17.0025 5 17.4167V18.75L5.00685 18.8518C5.05651 19.2178 5.3703 19.5 5.75 19.5H19.0833L19.1851 19.4932C19.5512 19.4435 19.8333 19.1297 19.8333 18.75V17.4167L19.8265 17.3149Z"
                  fill="#003448"
                />
              </svg>
              {t('Investor Statement')}
            </button>
          </div>
        </div>
        <div className="tabs d-flex align-items-center pointer userSelect">
          <div className={isButton ? 'tabsName checked' : 'tabsName'} onClick={() => LAND()}>
            <p>{t('LAND')}</p>
          </div>
          <div className={!isButton ? 'tabsName checked' : 'tabsName'} onClick={() => LOAN()}>
            <p>{t('LOAN')}</p>
          </div>
        </div>
        {isButton ? (
          <div className="tabsContent">
            <div className="total-box">
              <div className="time d-flex align-items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  className="date-icon"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.00016 1.33203C4.32394 1.33203 1.3335 4.32247 1.3335 7.9987C1.3335 11.6749 4.32394 14.6654 8.00016 14.6654C11.6764 14.6654 14.6668 11.6749 14.6668 7.9987C14.6668 4.32247 11.6764 1.33203 8.00016 1.33203ZM8.00016 13.6326C4.89404 13.6326 2.36628 11.1048 2.36628 7.9987C2.36628 4.89258 4.89404 2.36482 8.00016 2.36482C11.1068 2.36482 13.634 4.89258 13.634 7.9987C13.634 11.1048 11.1063 13.6326 8.00016 13.6326ZM8.51654 7.7818L10.3756 9.17607C10.6038 9.347 10.6503 9.67078 10.4788 9.89854C10.3776 10.0349 10.2227 10.1056 10.0652 10.1056C9.95727 10.1056 9.84881 10.0721 9.75587 10.0023L7.69029 8.45315C7.56016 8.35607 7.48373 8.20268 7.48373 8.04003V4.94164C7.48373 4.65607 7.71456 4.42523 8.00014 4.42523C8.28571 4.42523 8.51654 4.65607 8.51654 4.94164V7.7818Z"
                    fill="#003448"
                  />
                </svg>
                {t('As of')} {datetime}
              </div>
              <div className="total total-bg1">
                <p>{t('Total Active Holding')}</p>
                <div className="amount-box d-flex justify-content-center">
                  <div className="vertical">
                    <div className="amount amount-left">
                      {t('USD')}
                      <span>{formatCurrency(totalActiveHolding?.USD)}&nbsp;</span>
                      {t('USD1')}
                      <br />
                    </div>
                    <div className="amount amount-right">
                      {t('CAD')}
                      <span>{formatCurrency(totalActiveHolding?.CAD)}&nbsp;</span>
                      {t('CAD1')}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {landpage(tabLANDObj)}
          </div>
        ) : (
          <div className="tabsContent">
            <div className="total-box">
              <div className="time d-flex align-items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  className="date-icon"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.00016 1.33203C4.32394 1.33203 1.3335 4.32247 1.3335 7.9987C1.3335 11.6749 4.32394 14.6654 8.00016 14.6654C11.6764 14.6654 14.6668 11.6749 14.6668 7.9987C14.6668 4.32247 11.6764 1.33203 8.00016 1.33203ZM8.00016 13.6326C4.89404 13.6326 2.36628 11.1048 2.36628 7.9987C2.36628 4.89258 4.89404 2.36482 8.00016 2.36482C11.1068 2.36482 13.634 4.89258 13.634 7.9987C13.634 11.1048 11.1063 13.6326 8.00016 13.6326ZM8.51654 7.7818L10.3756 9.17607C10.6038 9.347 10.6503 9.67078 10.4788 9.89854C10.3776 10.0349 10.2227 10.1056 10.0652 10.1056C9.95727 10.1056 9.84881 10.0721 9.75587 10.0023L7.69029 8.45315C7.56016 8.35607 7.48373 8.20268 7.48373 8.04003V4.94164C7.48373 4.65607 7.71456 4.42523 8.00014 4.42523C8.28571 4.42523 8.51654 4.65607 8.51654 4.94164V7.7818Z"
                    fill="#003448"
                  />
                </svg>
                {t('As of')} {datetime}
              </div>
              <div className="total total-bg2">
                <p>{t('Total Active Loan Amount')}</p>
                <div className="amount-box d-flex justify-content-center">
                  <div className="vertical">
                    <div className="amount amount-left">
                      {t('USD')}
                      <span>{formatCurrency(totalActiveLoanHolding?.USD)}&nbsp;</span>
                      {t('USD1')}
                    </div>
                    <div className="amount amount-right">
                      {t('CAD')}
                      <span>{formatCurrency(totalActiveLoanHolding?.CAD)}&nbsp;</span>
                      {t('CAD1')}
                    </div>
                  </div>
                </div>
              </div>
              <div className="total total-bg3">
                <p>{t('Total Loan Payment')}</p>
                <div className="amount-box d-flex justify-content-center">
                  <div className="vertical">
                    <div className="amount amount-left">
                      {t('USD')}
                      <span>{formatCurrency(totalPaymentAmount?.USD)}&nbsp;</span>
                      {t('USD1')}
                    </div>
                    <div className="amount amount-right">
                      {t('CAD')}
                      <span>{formatCurrency(totalPaymentAmount?.CAD)}&nbsp;</span>
                      {t('CAD1')}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {landpage(tabLAODObj)}
          </div>
        )}
      </div>
    </div>
  );
};
export default Investments;
